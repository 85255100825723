var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Modal", {
    attrs: { show: _vm.showModal, title: _vm.title, submit: _vm.submitLabel },
    on: {
      submit: _vm.handleSubmit,
      cancel: _vm.handleCancel,
      close: _vm.handleCancel,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _vm.contactEntitiesWithoutAbuseLength > 0
              ? _c(
                  "div",
                  { staticClass: "form-wrapper" },
                  [
                    _c("h4", [
                      _vm._v(
                        "Check the box next to the field you think is inaccurate."
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.contactEntitiesWithoutAbuse,
                      function (entity, index) {
                        return _c("Contact", {
                          key: `entity${index}`,
                          attrs: { entity: entity, isReport: _vm.isReport },
                        })
                      }
                    ),
                    _vm._v(" "),
                    _vm.isFieldsListEmpty
                      ? _c("p", { staticClass: "required-message" }, [
                          _vm._v(
                            "\n          Please check at least one box to report\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "group-wrapper" }, [
                      _c("div", { staticClass: "input-row" }, [
                        _c("label", { attrs: { for: "reportReason" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.i18n.t("Reason for inaccuracy report:")
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.reportReason,
                                expression: "reportReason",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "multiline-input",
                            attrs: { rows: "3" },
                            domProps: { value: _vm.reportReason },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.reportReason = $event.target.value.trim()
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.isReasonEmpty
                          ? _c(
                              "p",
                              { staticClass: "required-message required" },
                              [
                                _vm._v(
                                  "\n              This is required\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ],
                  2
                )
              : _c("p", [_vm._v("There is no fields to report")]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }