var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasAcceptedTerms
    ? _c(
        "main",
        [
          _c("SearchFilters", { on: { search: _vm.handleSearch } }),
          _vm._v(" "),
          _c(
            "section",
            [_c("SearchResults", { attrs: { results: _vm.results } })],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }