<template>
  <Modal
    :show="showModal"
    :title="title"
    :submit="submitLabel"
    @submit="handleSubmit"
    @cancel="handleCancel"
    @close="handleCancel"
  >
    <template v-slot:body>
      <div v-if="contactEntitiesWithoutAbuseLength > 0" class="form-wrapper">
        <h4>Check the box next to the field you think is inaccurate.</h4>
        <Contact
          v-for="(entity, index) in contactEntitiesWithoutAbuse"
          :key="`entity${index}`"
          :entity="entity"
          :isReport="isReport"
        />
        <p v-if="isFieldsListEmpty" class="required-message">
          Please check at least one box to report
        </p>
        <div class="group-wrapper">
          <div class="input-row">
            <label for="reportReason">
              {{ i18n.t('Reason for inaccuracy report:') }}
            </label>
            <div>
              <textarea
                v-model.trim="reportReason"
                class="multiline-input"
                rows="3"
              />
            </div>
            <p v-if="isReasonEmpty" class="required-message required">
              This is required
            </p>
          </div>
			</div>
      </div>
      <p v-else>There is no fields to report</p>
    </template>
  </Modal>
</template>

<script>
/**
 * @class ReportModal
 *
 * Display Report modal
 */
import Modal from "tucows-ui-components/src/Modal/Modal";
import Contact from "./Contact.vue";

import { mapActions, mapState, mapMutations } from "vuex";
import _get from "lodash/get";

export default {
  name: "ReportModal",
  components: {
    Modal,
    Contact,
  },
  props: {
    value: Boolean,
    contactEntities: Array,
  },
  data() {
    return {
      title: "Report Inaccurate Data",
      showModal: Boolean(this.value),
      isSubmitted: false,
      isReport: true,
      submitLabel: { label: "Report" },
      reportReason: "",
    };
  },
  computed: {
    ...mapState({
      domain: (state) => _get(state, "domain.domain"),
      brand: (state) => _get(state, "domain.brand"),
      concatedFields: (state) => _get(state, "report.fields"),
    }),
    /**
     * if the fields list is empty when submitted
     * @return {boolean}
     */
    isFieldsListEmpty() {
      return this.isSubmitted && this.allFields().length < 1;
    },
    /**
     * removing abuse contact
     * @return {Array} entities
     */
    contactEntitiesWithoutAbuse() {
      return this.contactEntities.filter(
        (obj) => !obj.role.match(/abuse|registrar/gi)
      );
    },
    /**
     * entities list length
     * @return {integer} length
     */
    contactEntitiesWithoutAbuseLength() {
      return this.contactEntitiesWithoutAbuse.length;
    },
    /**
     * if the fields list is empty when submitted
     * @return {boolean}
     */
     isReasonEmpty() {
      return this.isSubmitted && !this.reportReason;
    },
  },
  watch: {
    /**
     * Watch for changes from value
     * @param {Boolean} val   Value
     */
    value(val) {
      this.showModal = Boolean(val);
    },
    /**
     * @param {Boolean} val
     */
    showModal(val) {
      Boolean(val) !== this.value && this.$emit("input", val);
    },
  },
  methods: {
    ...mapActions({
      showLoading: "common/showLoading",
      hideLoading: "common/hideLoading",
      report: "report/reportData",
    }),
    ...mapMutations({
      reset: "report/reset",
    }),
    /**
     * handles the closing/cancel of the modal
     */
    handleCancel() {
      this.showModal = false;
      this.isSubmitted = false;
      this.reportReason = "";
      this.reset();
    },
    /**
     * concat all contact's fields reported into one array
     */
    allFields() {
      let fields = [];
      for (const key in this.concatedFields)
        fields = fields.concat(this.concatedFields[key]);
      return fields;
    },

    /**
     * handles report button
     */
    handleSubmit() {
      this.isSubmitted = true;
      if (this.allFields().length >= 1 && this.reportReason) {
        this.showLoading();
        this.showModal = false;

        this.report({
          fields: this.allFields(),
          domain: this.domain,
          brand: this.brand,
          report_reason: this.reportReason
        }).then(() => {
          this.hideLoading();
        });

        this.isSubmitted = false;
        this.reportReason = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "common";
.form-wrapper {
  margin: 0rem 2rem 0rem 2rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.form-wrapper::-webkit-scrollbar {
  display: none;
}
.note {
  margin-bottom: 0rem;
}
.required-message {
  margin: -1rem 0 0 0;
  padding: 0.3rem;
  color: red;
  width: 100%;
  font-size: 0.857rem;
}
p {
  font-size: 1.2rem;
  margin: 1.5rem;
}
.required {
  margin-top: 0px;
}
</style>
