var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filters" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "group-wrapper" },
          [
            _vm.showBrandSelection
              ? _c("SelectField", {
                  attrs: {
                    options: _vm.brandList,
                    "show-message": false,
                    "show-placeholder": false,
                    label: "Brand",
                  },
                  model: {
                    value: _vm.selectedBrand,
                    callback: function ($$v) {
                      _vm.selectedBrand = $$v
                    },
                    expression: "selectedBrand",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "group-wrapper" },
          [
            _c("InputField", {
              attrs: {
                label: "Domain",
                required: true,
                "show-message": _vm.isDomainEmpty,
                message: _vm.i18n.t("This is required"),
                infoMessage: _vm.searchText.label,
                "message-class-name": "message required-message",
              },
              model: {
                value: _vm.searchText.value,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.searchText,
                    "value",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "searchText.value",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.hasPermission
          ? _c("div", { staticClass: "group-wrapper" }, [
              _c("div", { staticClass: "input-row" }, [
                _c("div", { staticClass: "flat-checkbox input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.disclose,
                        expression: "disclose",
                      },
                    ],
                    attrs: { id: "disclose", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.disclose)
                        ? _vm._i(_vm.disclose, null) > -1
                        : _vm.disclose,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.disclose,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.disclose = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.disclose = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.disclose = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "disclose" } }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.i18n.t("Do not disclose")) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "message" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(
                        _vm.i18n.t(
                          "When selected, your name, information, and the reason you provide for your lookup request will not be disclosed to the domain owner. They will still be notified that the request occurred and whether the data were provided or not. Non-disclosure requests will be reviewed; abusive requests for non-disclosure are a violation of the "
                        )
                      ) +
                      "\n\t\t\t\t\t"
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "terms-link",
                      attrs: {
                        href: "/terms",
                        target: "_self",
                        rel: "noopener",
                      },
                    },
                    [_vm._v(_vm._s(_vm.i18n.t("Terms of Service")))]
                  ),
                  _vm._v(
                    _vm._s(
                      _vm.i18n.t(
                        " and misuse may result in account suspension."
                      )
                    ) + "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.disclose
          ? [
              _c("div", { staticClass: "group-wrapper" }, [
                _c("div", { staticClass: "input-row" }, [
                  _c("label", [_vm._v("Reason for not disclosing")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group" }, [
                    _c("div", { staticClass: "input-wrapper" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.discloseReason,
                            expression: "discloseReason",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "multiline-input",
                        attrs: {
                          maxLength: _vm.disclosureMaxLength,
                          rows: "3",
                        },
                        domProps: { value: _vm.discloseReason },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.discloseReason = $event.target.value.trim()
                            },
                            _vm.updateDisclosureReasonCharacterCount,
                          ],
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "message" }, [
                    _c("div", { attrs: { align: "right" } }, [
                      _vm._v(
                        _vm._s(_vm.characterCountForDisclosureReason) +
                          " / 1000"
                      ),
                    ]),
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(
                          _vm.i18n.t(
                            "This will be sent to the Tiered Access administration team."
                          )
                        ) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isDiscloseReasonEmpty
                    ? _c("p", { staticClass: "required-message" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.i18n.t("This is required.")) +
                            "\n\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "group-wrapper" }, [
          _c("div", { staticClass: "input-row" }, [
            _c("label", [_vm._v("Reason for lookup")]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              _c("div", { staticClass: "input-wrapper" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.reason,
                      expression: "reason",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "multiline-input",
                  attrs: { maxlength: _vm.reasonMaxLength, rows: "3" },
                  domProps: { value: _vm.reason },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.reason = $event.target.value.trim()
                      },
                      _vm.updateReasonCharacterCount,
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "message",
                class: { "required-message": _vm.isReasonEmpty },
              },
              [
                _c("div", { attrs: { align: "right" } }, [
                  _vm._v(_vm._s(_vm.characterCountForReason) + " / 2500"),
                ]),
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(
                      _vm.i18n.t(
                        "Please provide a complete and accurate explanation of the legitimate purpose you believe justifies the disclosure of personal data related to this domain name. Your name, contact information, and any explanation provided here may be disclosed to the domain name owner and so should be clear."
                      )
                    ) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "group-wrapper" },
          [
            _c("Btn", {
              attrs: { label: _vm.searchButton.label },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleClick.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }