var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.results
    ? _c(
        "div",
        [
          _c(
            "section",
            [
              _vm.results.unicodeName
                ? _c("div", { staticClass: "domainName" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Domain Name:"))),
                    ]),
                    _vm._v(
                      "\n\t\t\t" + _vm._s(_vm.results.unicodeName) + "\n\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.updatedLastDate
                ? _c("div", { staticClass: "updatedDate" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Updated Date:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(_vm.updatedLastDate) + "\n\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.creationDate
                ? _c("div", { staticClass: "creationDate" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Creation Date:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(_vm.creationDate) + "\n\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.expiryDate
                ? _c("div", { staticClass: "registryExpiryDate" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Registry Expiry Date:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(_vm.expiryDate) + "\n\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.status
                ? _c(
                    "div",
                    [
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.i18n.t("Domain Status:"))),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.status, function (s, i) {
                        return _c("span", { key: `status${i}` }, [
                          _vm._v(_vm._s(s) + " "),
                        ])
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.results.nameservers, function (n, i) {
                return _c(
                  "div",
                  { key: `nameserver${i}`, class: `nameserver${i}` },
                  [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Namserver:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(n.unicodeName) + "\n\t\t"),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.dnssec
                ? [
                    _vm.dnssec
                      ? _c("div", { staticClass: "dnnsec" }, [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(_vm.i18n.t("DNSSEC:"))),
                          ]),
                          _vm._v(
                            "\n\t\t\t\t" + _vm._s(_vm.dnssecSigned) + "\n\t\t\t"
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.registrar
                ? _c("div", { staticClass: "capitalize registrar" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Registrar:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(_vm.registrar) + "\n\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ianaId
                ? _c("div", { staticClass: "ianaId" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Registrar IANA ID:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(_vm.ianaId) + "\n\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.port43
                ? _c("div", { staticClass: "port43" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Registrar Whois Server:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(_vm.port43) + "\n\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.registrarURL
                ? _c("div", { staticClass: "registrarURL" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Registrar URL:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(_vm.registrarURL) + "\n\t\t"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.lastUpdateOfWhoisDB
                ? _c("div", { staticClass: "lastUpdateOfWhoisDB" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(
                        _vm._s(_vm.i18n.t("Last Update of Whois Database:"))
                      ),
                    ]),
                    _vm._v(
                      "\n\t\t\t" + _vm._s(_vm.lastUpdateOfWhoisDB) + "\n\t\t"
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "registry-fields" }, [
                _vm.registryDomainID
                  ? _c("div", { staticClass: "registryDomainID" }, [
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.i18n.t("Registry Domain ID:"))),
                      ]),
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.registryDomainID) + "\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.registryRegistrantID
                  ? _c("div", { staticClass: "registryRegistrantID" }, [
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.i18n.t("Registry Registrant ID:"))),
                      ]),
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.registryRegistrantID) +
                          "\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.registryTechID
                  ? _c("div", { staticClass: "registryTechID" }, [
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.i18n.t("Registry Technical ID:"))),
                      ]),
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.registryTechID) + "\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.registryAdminID
                  ? _c("div", { staticClass: "registryAdminID" }, [
                      _c("span", { staticClass: "bold" }, [
                        _vm._v(_vm._s(_vm.i18n.t("Registry Admin ID:"))),
                      ]),
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.registryAdminID) + "\n\t\t\t"
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.reseller
                ? _c("div", { staticClass: "reseller" }, [
                    _c("span", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.i18n.t("Reseller:"))),
                    ]),
                    _vm._v("\n\t\t\t" + _vm._s(_vm.reseller) + "\n\t\t"),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.entities
            ? _vm._l(_vm.contactEntities, function (entity, index) {
                return _c("Contact", {
                  key: `entity${index}`,
                  attrs: { entity: entity, isReport: _vm.isReport },
                })
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.rddsForm
            ? _c("div", { staticClass: "rddsForm" }, [
                _c("span", { staticClass: "bold" }, [
                  _vm._v(
                    _vm._s(_vm.i18n.t("RDDS Inaccuracy Complaint Form: "))
                  ),
                ]),
                _vm._v("\n\t\t" + _vm._s(_vm.rddsForm) + "\n\t"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("a", { on: { click: _vm.handleClickReport } }, [
                _vm._v("Click here if you think these data are inaccurate."),
              ]),
              _vm._v(" "),
              _c("ReportModal", {
                attrs: { contactEntities: _vm.contactEntities },
                model: {
                  value: _vm.showModal,
                  callback: function ($$v) {
                    _vm.showModal = $$v
                  },
                  expression: "showModal",
                },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }