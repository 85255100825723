<template>
	<div v-if="results">
		<section>
			<div
				v-if="results.unicodeName"
				class="domainName"
			>
				<span class="bold">{{ i18n.t('Domain Name:') }}</span>
				{{ results.unicodeName }}
			</div>
			<div
				v-if="updatedLastDate"
				class="updatedDate"
			>
				<span class="bold">{{ i18n.t('Updated Date:') }}</span>
				{{ updatedLastDate }}
			</div>
			<div
				v-if="creationDate"
				class="creationDate"
			>
				<span class="bold">{{ i18n.t('Creation Date:') }}</span>
				{{ creationDate }}
			</div>
			<div
				v-if="expiryDate"
				class="registryExpiryDate"
			>
				<span class="bold">{{ i18n.t('Registry Expiry Date:') }}</span>
				{{ expiryDate }}
			</div>
			<div v-if="status">
				<span class="bold">{{ i18n.t('Domain Status:') }}</span>
				<span
					v-for="(s, i) in status"
					:key="`status${i}`"
				>{{ s }} </span>
			</div>
			<div
				v-for="(n,i) in results.nameservers"
				:key="`nameserver${i}`"
				:class="`nameserver${i}`"
			>
				<span class="bold">{{ i18n.t('Namserver:') }}</span>
				{{ n.unicodeName }}
			</div>

			<template v-if="dnssec">
				<div
					v-if="dnssec"
					class="dnnsec"
				>
					<span class="bold">{{ i18n.t('DNSSEC:') }}</span>
					{{ dnssecSigned }}
				</div>
			</template>

			<div
				v-if="registrar"
				class="capitalize registrar"
			>
				<span class="bold">{{ i18n.t('Registrar:') }}</span>
				{{ registrar }}
			</div>
			<div
				v-if="ianaId"
				class="ianaId"
			>
				<span class="bold">{{ i18n.t('Registrar IANA ID:') }}</span>
				{{ ianaId }}
			</div>
			<div
				v-if="port43"
				class="port43"
			>
				<span class="bold">{{ i18n.t('Registrar Whois Server:') }}</span>
				{{ port43 }}
			</div>

			<div
				v-if="registrarURL"
				class="registrarURL"
			>
				<span class="bold">{{ i18n.t('Registrar URL:') }}</span>
				{{ registrarURL }}
			</div>
			<div
				v-if="lastUpdateOfWhoisDB"
				class="lastUpdateOfWhoisDB"
			>
				<span class="bold">{{ i18n.t('Last Update of Whois Database:') }}</span>
				{{ lastUpdateOfWhoisDB }}
			</div>
			<div class="registry-fields">
				<div
					v-if="registryDomainID"
					class="registryDomainID"
				>
					<span class="bold">{{ i18n.t('Registry Domain ID:') }}</span>
					{{ registryDomainID }}
				</div>
				<div
					v-if="registryRegistrantID"
					class="registryRegistrantID"
				>
					<span class="bold">{{ i18n.t('Registry Registrant ID:') }}</span>
					{{ registryRegistrantID }}
				</div>
				<div
					v-if="registryTechID"
					class="registryTechID"
				>
					<span class="bold">{{ i18n.t('Registry Technical ID:') }}</span>
					{{ registryTechID }}
				</div>
				<div
					v-if="registryAdminID"
					class="registryAdminID"
				>
					<span class="bold">{{ i18n.t('Registry Admin ID:') }}</span>
					{{ registryAdminID }}
				</div>
			</div>
			<div
				v-if="reseller"
				class="reseller"
			>
				<span class="bold">{{ i18n.t('Reseller:') }}</span>
				{{ reseller }}
			</div>
		</section>
		<template
			v-if="entities"
		>
			<Contact
				v-for="(entity, index) in contactEntities"
				:key="`entity${index}`"
				:entity="entity"
				:isReport="isReport"
			/>
		</template>
		<div
			v-if="rddsForm"
			class="rddsForm"
		>
			<span class="bold">{{ i18n.t('RDDS Inaccuracy Complaint Form: ') }}</span>
			{{ rddsForm }}
		</div>
		<div>
			<a @click="handleClickReport">Click here if you think these data are inaccurate.</a>
			<ReportModal
			v-model="showModal"
			:contactEntities="contactEntities"
			/>
		</div>
	</div>
</template>

<script>
/**
 * @class SearchResults
 *
 * Display registrant data access protocol (RDAP)
 */
import {mapGetters} from 'vuex';
import Contact from './component/Contact.vue';
import ReportModal from './component/ReportModal.vue'
import _find from 'lodash/find';
import _get from 'lodash/get';

export default {
	name: 'SearchResults',
	components: {Contact, ReportModal},
	props: {
		results: {
			type: Object,
			/**
			 * Get contact information for a domain
			 * @return {object} domain's info
			 */
			default() {
				return {};
			}
		}
	},
	data(){
		return {
			showModal: false,
			isReport: false,
		}
	},
	computed: {
		...mapGetters({entities: 'domain/entities'}),
		/**
		 * Domain creation date
		 * @return {string} creationDate
		 */
		creationDate() {
			const event = _find(
				_get(this.results, 'events'),
				(e) => _get(e, 'eventAction', '').match(/registration/gi)
			);

			return _get(event, 'eventDate');
		},
		/**
		 * Domain expiry date
		 * @return {string} expiryDate
		 */
		expiryDate() {
			const event = _find(
				_get(this.results, 'events'),
				(e) => _get(e, 'eventAction', '').match(/expiration/gi)
			);

			return _get(event, 'eventDate');
		},
		/**
		 * Domain updated last date
		 * @return {string} updatedLastDate
		 */
		updatedLastDate() {
			const event = _find(
				_get(this.results, 'events'),
				(e) => _get(e, 'eventAction', '').match(/last changed/gi)
			);

			return _get(event, 'eventDate');
		},
		/**
		 * Domain's dnssec
		 * @return {string} dnssec
		 */
		dnssec() {
			return _get(this.results, 'secureDNS');
		},
		/**
		 * Domain's dnssec signed
		 * @return {string} dnssecSigned
		 */
		dnssecSigned() {
			return _get(this.results, 'secureDNS.zoneSigned') ? 'signed' : 'unsigned';
		},
		/**
		 * Domain's iana id
		 * @return {string} iana id
		 */
		ianaId() {
			const publicIds = _get(this.results, 'publicIds');
			const publicId = _find(publicIds, (item) => {
				const type = _get(item, 'type');
				return type === 'IANA Registrar ID';
			});

			return _get(publicId, 'identifier');
		},

		/**
		 * A list of all entities
		 * @return {array} List of entities
		 */
		allEntities() {
			let formattedEntities = this.entities(_get(this.results, 'entities', []));
			return formattedEntities.sort(function(a) {
				const role = _get(a, 'role', '');
				if (role.match(/abuse/gi)) {
					return -1;
				}
			});
		},

		/**
		 * Only display roles which are not registrar and
		 * reseller since their order needs to be at the top
		 * @return {array} list of contact entities for display
		 */
		contactEntities() {
			return this.allEntities.filter((item) => _get(item, 'role', '') !== 'registrar' && _get(item, 'role', '') !== 'reseller');
		},
		/**
		 * Registrar
		 * @return {string} registrar
		 */
		registrar() {
			const registrar = _find(this.allEntities, (entity) => {
				const role = _get(entity, 'role');
				return role === 'registrar';
			});
			return _get(registrar, 'fn');
		},

		/**
		 * Registry domain id
		 * @return {string} registry
		 */
		registryDomainID() {
			return _get(this.results, 'registry.registry_domain_id');
		},

		/**
		 * Registry registrant id
		 * @return {string} registry
		 */
		registryRegistrantID() {
			return _get(this.results, 'registry.registry_registrant_id');
		},

		/**
		 * Registry technical id
		 * @return {string} registry
		 */
		registryTechID() {
			return _get(this.results, 'registry.registry_tech_id');
		},

		/**
		 * Registry admin id
		 * @return {string} registry
		 */
		registryAdminID() {
			return _get(this.results, 'registry.registry_admin_id');
		},

		/**
		 * Display domain status
		 * @return {array} List of status
		 */
		status() {
			const status = _get(this.results, 'status');
			return _get(status, 'length', 0) > 0 && status;
		},

		/**
		* Display link for RDDS Inaccuracy Complaint Form
		* @return {string} form url
		*/
		rddsForm() {
			const rdds = (_get(this.results, 'notices', []) || []).find((item) => item.title === 'RDDS Inaccuracy Complaint Form');
			return _get(rdds, 'links.0.href');
		},

		/**
		* Display whois server url
		* @return {string} url
		*/
		port43() {
			return _get(this.results, 'port43');
		},

		/**
		* Display registrar url
		* @return {string} url
		*/
		registrarURL() {
			return _get(this.results, 'registrar_url');
		},

		/**
		* Display last update of whois server
		* @return {string} url
		*/
		lastUpdateOfWhoisDB() {
			return _get(this.results, 'update_whois_date');
		},

		/**
		 * Reseller name
		 * @return {string} registrar
		 */
		reseller() {
			const registrar = _find(this.allEntities, (entity) => {
				const role = _get(entity, 'role');
				return role === 'reseller';
			});

			return _get(registrar, 'fn');
		}
	},
	methods: {
		handleClickReport(){
			this.showModal = true;
		}
	}
};
</script>

<style scoped lang="less">
@import "theme";

section {
	padding: calc(@p20px / 2) 0;
}
.registry-fields{
	padding: calc(@p20px / 2) 0;
}
</style>
