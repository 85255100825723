<template>
	<main v-if="hasAcceptedTerms">
		<SearchFilters @search="handleSearch" />
		<section>
			<SearchResults :results="results" />
		</section>
	</main>
</template>

<script>
/**
	 * @class Search
	 * 
	 * Search domain name and display registration data access protocol (RDAP).
	 * 
	 * The Registration Data Access Protocol (RDAP) enables users to access current
	 * registration data and was created as an eventual replacement for the WHOIS protocol.
	 */
import {mapActions, mapState, mapGetters} from 'vuex';
import SearchFilters from './SearchFilters.vue';
import SearchResults from './SearchResults.vue';
import _get from 'lodash/get';

export default {
	name: 'Search',
	components: {
		SearchFilters,
		SearchResults
	},
	data: () => ({results: null}),
	computed: {
		...mapState({
			domainData: (state) => _get(state, 'domain.data'),
			errors: (state) => _get(state, 'domain.errors')
		}),
		...mapGetters({'hasAcceptedTerms': 'user/hasAcceptedTerms'})
	},
	methods: {
		...mapActions({'getRegistrationData': 'domain/getRegistrationData'}),
		/** 
			 * Get registration data for domain name
			 * @param {string} searchTerm	Domain name
			 * @param {string} brand		Brand name
			 * @param {object} ev			Event
			 */
		handleSearch({searchTerm = '', brand, reason, disclose, discloseReason}) {
			this.getRegistrationData({
				domain: searchTerm.trim(),
				brand:brand,
				reason:reason,
				opt_out:disclose,
				reason_for_not_disclosing:discloseReason
			}).then(() => {
				this.results = this.domainData;
			});
		}
	}
};
</script>
