var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { class: _vm.role }, [
    _vm.name && !_vm.role.match(/abuse|registrar/gi)
      ? _c("div", { staticClass: "name" }, [
          _vm.isReport
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fields,
                    expression: "fields",
                  },
                ],
                staticClass: "report-checkbox",
                attrs: { type: "checkbox" },
                domProps: {
                  value: _vm.role + "-name: " + _vm.name,
                  checked: Array.isArray(_vm.fields)
                    ? _vm._i(_vm.fields, _vm.role + "-name: " + _vm.name) > -1
                    : _vm.fields,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.fields,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.role + "-name: " + _vm.name,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.fields = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.fields = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.fields = $$c
                    }
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "bold capitalize" }, [
            _vm._v(_vm._s(_vm.role) + " " + _vm._s(_vm.i18n.t("Name:"))),
          ]),
          _vm._v("\n\t\t" + _vm._s(_vm.name) + "\n\t"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.organization
      ? _c("div", { staticClass: "organization" }, [
          _vm.isReport
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fields,
                    expression: "fields",
                  },
                ],
                staticClass: "report-checkbox",
                attrs: { type: "checkbox" },
                domProps: {
                  value: _vm.role + "-organization: " + _vm.organization,
                  checked: Array.isArray(_vm.fields)
                    ? _vm._i(
                        _vm.fields,
                        _vm.role + "-organization: " + _vm.organization
                      ) > -1
                    : _vm.fields,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.fields,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.role + "-organization: " + _vm.organization,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.fields = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.fields = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.fields = $$c
                    }
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "bold capitalize" }, [
            _vm._v(
              _vm._s(_vm.role) + " " + _vm._s(_vm.i18n.t("Organization:"))
            ),
          ]),
          _vm._v("\n\t\t" + _vm._s(_vm.organization) + "\n\t"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.address && _vm.address.length > 0
      ? _c("div", { staticClass: "address" }, [
          _c(
            "div",
            { attrs: { id: "address" } },
            [
              _c(
                "span",
                {
                  staticClass: "bold capitalize",
                  class: { shiftRight: _vm.isReport },
                },
                [
                  _vm._v(
                    _vm._s(_vm.role) + " " + _vm._s(_vm.i18n.t("Address:"))
                  ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.address, function (a, i) {
                return _c("div", { key: `address${i}` }, [
                  _vm.isReport
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fields,
                            expression: "fields",
                          },
                        ],
                        staticClass: "report-checkbox",
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: _vm.role + "-address: " + a,
                          checked: Array.isArray(_vm.fields)
                            ? _vm._i(_vm.fields, _vm.role + "-address: " + a) >
                              -1
                            : _vm.fields,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.fields,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = _vm.role + "-address: " + a,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.fields = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.fields = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.fields = $$c
                            }
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(Array.isArray(a) ? a.join(", ") : a) +
                      "\n\t\t\t\t"
                  ),
                ])
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.phone
      ? _c(
          "div",
          { staticClass: "phone" },
          [
            _vm.isReport
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.fields,
                      expression: "fields",
                    },
                  ],
                  staticClass: "report-checkbox",
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: _vm.role + "-phone: " + _vm.phone,
                    checked: Array.isArray(_vm.fields)
                      ? _vm._i(_vm.fields, _vm.role + "-phone: " + _vm.phone) >
                        -1
                      : _vm.fields,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.fields,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = _vm.role + "-phone: " + _vm.phone,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.fields = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.fields = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.fields = $$c
                      }
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "bold capitalize" }, [
              _vm._v(_vm._s(_vm.role) + " " + _vm._s(_vm.i18n.t("Phone:"))),
            ]),
            _vm._v("\n\t\t" + _vm._s(_vm.phone) + " "),
            _vm.phoneExt
              ? [_vm._v("\n\t\t\tx" + _vm._s(_vm.phoneExt) + "\n\t\t")]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.fax
      ? _c(
          "div",
          { staticClass: "fax" },
          [
            _vm.isReport
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.fields,
                      expression: "fields",
                    },
                  ],
                  staticClass: "report-checkbox",
                  attrs: { type: "checkbox" },
                  domProps: {
                    value: _vm.role + "-fax: " + _vm.fax,
                    checked: Array.isArray(_vm.fields)
                      ? _vm._i(_vm.fields, _vm.role + "-fax: " + _vm.fax) > -1
                      : _vm.fields,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.fields,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = _vm.role + "-fax: " + _vm.fax,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.fields = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.fields = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.fields = $$c
                      }
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "bold capitalize" }, [
              _vm._v(_vm._s(_vm.role) + " " + _vm._s(_vm.i18n.t("Fax:"))),
            ]),
            _vm._v("\n\t\t" + _vm._s(_vm.fax) + " "),
            _vm.faxExt
              ? [_vm._v("\n\t\t\tx" + _vm._s(_vm.faxExt) + "\n\t\t")]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.email
      ? _c("div", { staticClass: "email" }, [
          _vm.isReport
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fields,
                    expression: "fields",
                  },
                ],
                staticClass: "report-checkbox",
                attrs: { type: "checkbox" },
                domProps: {
                  value: _vm.role + "-email: " + _vm.email,
                  checked: Array.isArray(_vm.fields)
                    ? _vm._i(_vm.fields, _vm.role + "-email: " + _vm.email) > -1
                    : _vm.fields,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.fields,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.role + "-email: " + _vm.email,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.fields = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.fields = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.fields = $$c
                    }
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "bold capitalize" }, [
            _vm._v(_vm._s(_vm.role) + " " + _vm._s(_vm.i18n.t("Email:"))),
          ]),
          _vm._v("\n\t\t" + _vm._s(_vm.email) + "\n\t"),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }