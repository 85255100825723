<template>
	<section
		:class="role"
	>
		<div
			v-if="name && !role.match(/abuse|registrar/gi)"
			class="name"
		>
			<input v-if="isReport" v-model="fields" class="report-checkbox" :value="role+'-name: '+name" type="checkbox">
			<span class="bold capitalize">{{ role }} {{ i18n.t('Name:') }}</span>
			{{ name }}
		</div>
		<div
			v-if="organization"
			class="organization"
		>
			<input v-if="isReport" v-model="fields" class="report-checkbox" :value="role+'-organization: '+ organization" type="checkbox">
			<span class="bold capitalize">{{ role }} {{ i18n.t('Organization:') }}</span>
			{{ organization }}
		</div>
		<div
			v-if="address && address.length > 0"
			class="address"
		>
			<div id="address">
				<span class="bold capitalize" :class="{shiftRight: isReport}">{{ role }} {{ i18n.t('Address:') }}</span>
				<template>
					<div
						v-for="(a,i) in address"
						:key="`address${i}`"
					>
						<input v-if="isReport" v-model="fields" class="report-checkbox" :value="role+'-address: '+ a " type="checkbox">
						{{ Array.isArray(a) ? a.join(', ') : a }}
					</div>
				</template>
			</div>
		</div>
		<div
			v-if="phone"
			class="phone"
		>
			<input v-if="isReport" v-model="fields" class="report-checkbox" :value="role+'-phone: '+ phone" type="checkbox">
			<span class="bold capitalize">{{ role }} {{ i18n.t('Phone:') }}</span>
			{{ phone }} <template v-if="phoneExt">
				x{{ phoneExt }}
			</template>
		</div>
		<div
			v-if="fax"
			class="fax"
		>
			<input v-if="isReport" v-model="fields" class="report-checkbox" :value="role+'-fax: '+ fax" type="checkbox">
			<span class="bold capitalize">{{ role }} {{ i18n.t('Fax:') }}</span>
			{{ fax }} <template v-if="faxExt">
				x{{ faxExt }}
			</template>
		</div>
		<div
			v-if="email"
			class="email"
		>
			<input v-if="isReport" v-model="fields" class="report-checkbox" :value="role+'-email: '+email" type="checkbox">
			<span class="bold capitalize">{{ role }} {{ i18n.t('Email:') }}</span>
			{{ email }}
		</div>
	</section>
</template>

<script>
/**
 * @class Contact
 *
 * Display contact details
 */
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _trim from 'lodash/trim';
import { mapActions } from "vuex";

export default {
	props: {
		entity: Object,
		isReport:{
			type: Boolean,
			default(){
				return false
			}
		},
	},
	data() {
    return {
      fields: [], //checkboxes values
    };
  },
  watch: {

	/**
     * Watch for changes on check boxes to add it to the fields
     * @param {Array} val   Value
     */
    fields(val) {
		this.addFields({roleData: this.role + "Data",newFields: val})
    },
  },
	computed: {
		/**
		 * @return {string} name
		 */
		name() {
			return _trim(_get(this.entity, 'fn'));
		},
		/**
		 * @return {array} address
		 */
		address() {
			return _filter(_get(this.entity, 'adr', []), (item) => _trim(item));
		},
		/**
		 * Return role type such as Registrant, Technical
		 * @return {string} role
		 */
		role() {
			return _get(this.entity, 'role');
		},
		/**
		 * @return {string} organization
		 */
		organization() {
			return _get(this.entity, 'org');
		},
		/**
		 * @return {string} phone
		 */
		phone() {
			// Remove tel:, ;ext=digits
			const tel = _get(this.entity, 'tel.voice', '').replace(
				/tel:|;ext=.\d+|;ext=/gi,
				''
			);
			return tel;
		},
		/**
		 * @return {string} phone extension
		 */
		phoneExt() {
			const ext = _get(this.entity, 'tel.voice', '');
			return ext.indexOf('ext') > -1 ? ext.replace(/.*;ext=/gi, '') : '';
		},
		/**
		 * @return {string} fax
		 */
		fax() {
			// Remove tel:, ;ext=digits
			const fax = _get(this.entity, 'tel.fax', '').replace(
				/tel:|;ext=.\d+|;ext=/gi,
				''
			);
			return fax;
		},
		/**
		 * @return {string} fax extension
		 */
		faxExt() {
			const ext = _get(this.entity, 'tel.fax', '');
			return ext.indexOf('ext') > -1 ? ext.replace(/.*;ext=/gi, '') : '';
		},
		/**
		 * @return {string} email
		 */
		email() {
			return _get(this.entity, 'email');
		}
	},
	methods:{
		...mapActions({ 'addFields': 'report/addFields' }),
	}
};
</script>

<style scoped lang="less">
@import "theme";

section {
	padding: calc(@p20px / 2) 0;
}
input[type="checkbox"] {
    display: inline-block;
    width: 15px;
    vertical-align: top;
}

.report-checkbox {
  display: inline-block;
  width: 200px;
  word-break: break-all;
}
#address {
	display:inline-block;
}
.shiftRight{
	margin-left: 1.8rem;
}
</style>
