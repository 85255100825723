<template>
	<div class="filters">
		<form @submit.prevent.stop="handleSubmit">
			<div class="group-wrapper">
				<SelectField
					v-if="showBrandSelection"
					v-model="selectedBrand"
					:options="brandList"
					:show-message="false"
					:show-placeholder="false"
					label="Brand"
				/>
			</div>
			<div class="group-wrapper">
				<InputField
					v-model.trim="searchText.value"
					label="Domain"
					:required="true"
					:show-message="isDomainEmpty"
					:message="i18n.t('This is required')"
					:infoMessage="searchText.label"
					message-class-name="message required-message"
				>
				</InputField>
			</div>

			<div v-if="hasPermission" class="group-wrapper">
				<div class="input-row">
					<div class="flat-checkbox input-group">
						<input
							id="disclose"
							type="checkbox"
							v-model="disclose"
						>
						<label for="disclose">
							{{ i18n.t('Do not disclose') }}
						</label>
					</div>
					<p class="message">
						{{ i18n.t('When selected, your name, information, and the reason you provide for your lookup request will not be disclosed to the domain owner. They will still be notified that the request occurred and whether the data were provided or not. Non-disclosure requests will be reviewed; abusive requests for non-disclosure are a violation of the ') }}
						<a
							class="terms-link"
							href="/terms"
							target="_self"
							rel="noopener"
						>{{i18n.t('Terms of Service')}}</a>{{i18n.t(' and misuse may result in account suspension.')}}
					</p>
				</div>
			</div>

			<template v-if="disclose">
				<div class="group-wrapper">
					<div class="input-row">
						<label>Reason for not disclosing</label>
						<div class="input-group">
							<div class="input-wrapper">
								<textarea
									@input="updateDisclosureReasonCharacterCount"
									v-model.trim="discloseReason"
									class="multiline-input"
									:maxLength="disclosureMaxLength"
									rows="3"
								/>
							</div>
						</div>
						<span class="message">
							<div align="right">{{ characterCountForDisclosureReason }} / 1000</div>
							{{ i18n.t('This will be sent to the Tiered Access administration team.') }}
						</span>
						<p v-if="isDiscloseReasonEmpty" class="required-message">
							{{ i18n.t('This is required.') }}
						</p>
					</div>
				</div>
			</template>

			<div class="group-wrapper">
				<div class="input-row">
					<label>Reason for lookup</label>
					<div class="input-group">
						<div class="input-wrapper">
							<textarea
								@input="updateReasonCharacterCount"
								v-model.trim="reason"
								class="multiline-input"
								:maxlength="reasonMaxLength"
								rows="3"
							/>
						</div>
					</div>
					<div v-bind:class='{"required-message":isReasonEmpty}' class="message">
						<div align="right">{{ characterCountForReason }} / 2500</div>
						{{ i18n.t('Please provide a complete and accurate explanation of the legitimate purpose you believe justifies the disclosure of personal data related to this domain name. Your name, contact information, and any explanation provided here may be disclosed to the domain name owner and so should be clear.') }}
					</div>
				</div>
			</div>
			<div class="group-wrapper">
				<Btn
					:label="searchButton.label"
					@click.prevent="handleClick"
				></Btn>
			</div>
		</form>
	</div>
</template>


<script>
/**
 * @class SearchFilters
 *
 * Display an input field to allow user to input domain name to return rdap data.
 */
import InputField from 'tucows-ui-components/src/Input/InputField.vue';
import SelectField from 'tucows-ui-components/src/Select/SelectField.vue';
import Btn from 'tucows-ui-components/src/Btn.vue';
import ToggleInput from 'tucows-ui-components/src/Input/ToggleInput.vue';
import i18n from '@/lib/i18n';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import {mapGetters} from 'vuex';

export default {
	name: 'SearchFilters',
	components: {
		InputField,
		SelectField,
		Btn,
		ToggleInput
	},
	/**
	 * @ignore
	 */
	data() {
		return {
			searchTerm: '',
			reason: '',
			discloseReason: '',
			disclose: false,
			isSubmitted: false,
			searchButton: {label: i18n.t('Lookup')},
			searchText: {
				label: i18n.t('Enter the domain (example.com) you\'d like to look up info for.'),
				value: ''
			},
			brand: '',
			showDomainRequiredMessage: false,
			characterCountForReason: 0,
			characterCountForDisclosureReason: 0,
			reasonMaxLength: 2500,
			disclosureMaxLength: 1000
		};
	},
	computed: {
		...mapGetters({
			brands: 'user/brands',
			'hasPermission': 'user/hasPermission' //If the user has the permission to see the do not disclose box
			}),

		/**
		 * Get a list of brands
		 * @return {array} brands
		 */
		brandList() {
			const list = [];

			const brandList = _cloneDeep(this.brands || []);

			// Create a list with [{name, value}], used by options in select field
			for (let i = 0; i < _get(brandList, 'length', 0); i++) {
				const brand = _get(brandList, i);
				list.push({
					name: _get(brand, 'name', '').replace('_', ' '),
					value: _get(brand, 'name', '')
				});
			}

			return list;
		},

		/**
		 * Determine the brand selected
		 */
		selectedBrand: {
			/**
			 * Change the brand
			 * @param {string} newBrand  Name of brand
			 */
			set(newBrand) {
				this.brand = newBrand;
			},

			/**
			 * Get name of brand selected
			 * @return {string}  brand name
			 */
			get() {
				// If no brand was assigned, then default to first item
				return _get(this, 'brand', '') || _get(this.brandList, '0.value', '');
			}
		},

		/**
		 * Show the brand dropdown when there is more than one brand
		 * @return {boolean} has more than one brand
		 */
		showBrandSelection() {
			return _get(this.brandList, 'length', 0) > 1;
		},

		/**
		 * is the reason field populated?
		 */
		isReasonEmpty() {
			return this.isSubmitted && this.reason.length < 1;
		},

		isDiscloseReasonEmpty() {
			if (this.disclose) {
				return this.isSubmitted && this.discloseReason.length < 1;
			}
			return false;
		},

		/**
		 * Determine if domain name is written
		 * @return {boolean} has domain name
		 */
		isDomainEmpty() {
			return this.isSubmitted && _get(this.searchText, 'value.length', 0) < 1;
		}
	},
	methods: {
		updateReasonCharacterCount(event) {
			const maxLength = 2500;
			let currentText = event.target.value;
			let newLines = 0;

			// Normalize new lines to just '\n' to ensure consistency across browsers
			const normalizedInput = currentText.replace(/\r\n/g, '').replace(/\r/g, '');
			newLines = (currentText.split(/\r\n|\r|\n/).length) - 1

			// Get the character count using the length property
			this.characterCountForReason = normalizedInput.length;

			if(this.isSafari()) {
				this.characterCountForReason = this.characterCountForReason + newLines
			}

			if(this.characterCountForReason === maxLength){
				this.reasonMaxLength = this.characterCountForReason
				// Limit the input to the max length
				const trimmedText = currentText.substring(0, this.reasonMaxLength);
				this.reason = trimmedText;
			}
		},
		updateDisclosureReasonCharacterCount(event) {
			const maxLength = 1000;
			let currentText = event.target.value;
			let newLines = 0;

			// Normalize new lines to just '\n' to ensure consistency across browsers
			const normalizedInput = currentText.replace(/\r\n/g, '').replace(/\r/g, '');
			newLines = (currentText.split(/\r\n|\r|\n/).length) - 1

			// Get the character count using the length property
			this.characterCountForDisclosureReason = normalizedInput.length

			if(this.isSafari()) {
				this.characterCountForDisclosureReason = this.characterCountForDisclosureReason + newLines
			}
			if ( this.characterCountForDisclosureReason === maxLength) {
				this.disclosureMaxLength = this.characterCountForDisclosureReason
				// Limit the input to the max length
				const trimmedText = currentText.substring(0, this.disclosureMaxLength);
				this.discloseReason = trimmedText;

			}
		},

		/**
		 * check is the browser being used is Safari
		 */
		isSafari() {
			return /^((?!Chrome|CriOS|FxiOS|EdgiOS|EdgA|Edg|OPiOS|OPR|YaBrowser|SamsungBrowser|UCBrowser|FBIOS|FBAN|FBAV|Instagram|Twitter|Pinterest|Line|Weibo|MicroMessenger).)*Safari/i.test(
				navigator.userAgent
			)
		},

		/**
		 * Handle search button click
		 * @param {object} ev  Event
		 */
		handleClick(ev) {
			this.search(ev);
		},

		/**
		 * If search term changed then emit search event
		 * @param {object} ev  Event
		 */
		search(ev) {
			this.isSubmitted = true;
			this.searchTerm = _get(this.searchText, 'value');
			this.showDomainRequiredMessage = !this.searchTerm;
			// no reason, no search
			if (!this.isReasonEmpty && this.searchTerm) {
				if (this.disclose === false) {
					this.emitSearch(ev);
				} else if (this.disclose === true && this.discloseReason) {
					this.emitSearch(ev);
				}
			}
		},
		emitSearch(ev) {
			this.$emit('search', {
				searchTerm: this.searchTerm,
				brand: this.selectedBrand,
				reason: this.reason,
				disclose: this.disclose,
				discloseReason: this.discloseReason,
				ev
			});
			this.disclose = false;
		},
		/**
		 * Submit for searching
		 * @param {object} ev  Event
		 */
		handleSubmit(ev) {
			this.search(ev);
		}
	}
};
</script>

<style lang="less">
@import "theme";

.filters {
	padding-top: 2rem;
	padding-bottom: 1rem;
	background: @grey1_lighter;
	.group-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		.input-row,
		.select {
			width: 600px;
			padding: 0 @p20px;
			background: none;
			select {
				background: white;
			}
		}
		.input-row:last-child {
			margin-top: 2rem;
		}
		.multiline-input {
			width: 100%;
			height: auto;
			padding: 0.712em 1em;
			font-size: 1rem;
			border-color: @border-colour;
		}
		.reason-message {
			display: none;
		}
		.input-information {
			display: inline-block;
			width: 100%;
		}
		.required-message {
			display: inline-block;
			color: red;
			width: 100%;
			font-size: 0.857rem;
			font-weight: inherit;
		}
	}
}

.select {
	background-color: @white;
}

select {
	text-transform: capitalize;
}
</style>
